@import '../../../../../assets/scss/medias';

.Buttons {
    @include media_desktop {
        grid-column: 1;
        grid-row: 1;
        align-self: end;
    }
}

.RemoveDeviceContainer,
.RegisterDeviceContainer {
    padding: 0 20px;
    margin: 1.5rem 0;

    @include media_desktop {
        padding: 0;
        grid-column: 1;
        grid-row: 1;
        margin-top: 98%;
    }
}

.DeviceRegisteringStatus {
    cursor: default;
    opacity: 0.4;
}

.RegisterBtnContainer {
    padding: 0 20px;
    margin: 1.5rem 0;
}

.RegisterButtons {
    @include media_tablet {
        max-width: 34vw;
    }

    @include media_desktop {
        width: auto;
    }
}

.RemoveRegisterButtons {
    @include media_desktop {
        height: 4.44444vh;
        margin: 0 auto;
        width: 342px;
        min-height: 2rem;
    }
}
