.HeaderButtonsContainer {
    right: 0;
    top: 0;
}

.LockButton {
    width: max-content;
    height: auto;
    margin: 0;
    line-height: unset;
    display: flex;
    float: right;
}

.LockIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LockIcon img {
    max-width: 15px;
    max-height: 15px;
    margin-left: 5px;
}

.LockButtonDisabled {
    opacity: 0.4;
}
