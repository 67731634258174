@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    background-color: var(--color-pearl-bush);
    padding: 60px 27px 20px 28px;
    min-height: 100%;

    @include media_tablet {
        width: 100%;
    }
}

.Header {
    color: var(--color-charade);
    margin: 0;

    @include media_desktop {
        width: px-to-vh(700px);
        margin: 1.35rem auto 0 auto;
        letter-spacing: -2px;
    }
}

.Description {
    text-align: center;

    @include media_tablet {
        margin: 10px 0 25px 0;
        font-size: px-to-vh(20px);
    }

    @include media_desktop {
        margin: 26px 0 0 0;
        font-size: px-to-vh(20px);
    }
}

.ImageContainer {
    height: 50vh;
    margin: 1.5rem 0 1.5rem;

    @include media_tablet {
        height: 347px;
        margin: 0 0 44px;
    }

    @include media_desktop {
        height: px-to-vh(434px);
        margin: 1.5rem 0 3.8rem;
    }
}

.GoToDeviceBtn {
    @include media_tablet {
        margin-top: 10px;
    }

    @include media_desktop {
        margin-top: 18px;
    }
}
