@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.HeaderContainer {
    @include media_desktop {
        position: absolute;
    }
}

.Page {
    width: 100%;

    @include media_desktop {
        display: flex;
        flex-direction: column;
        margin-top: px-to-vh(84px);
        padding-bottom: px-to-vh(136px);
    }
}

.IsIntroSlide {
    background-color: var(--color-pearl-bush);
}

.ContentWrapper {
    outline: none;
}

.NextSlideButton {
    position: relative;
    margin: 2.25rem 0;

    @include media_tablet {
        margin: 1rem 0;
    }
}

@include media_desktop {
    .BackButtonContainer {
        position: absolute;
    }
}

.SliderButton {
    background-color: var(--color-alabaster-lighten);
    top: 54%;

    &:global(.slick-disabled) {
        cursor: pointer;
    }
}

.Dots {
    bottom: -25px;

    @include media_tablet {
        bottom: px-to-vh-tablet(-25px);
    }

    @include media_desktop {
        bottom: -25px;
    }

    li {
        margin: 0 5px 0;

        @include media_tablet {
            margin-right: 6px;
        }

        button {
            &:before {
                font-size: 5px;

                @include media_tablet {
                    font-size: 7px;
                }
            }
        }

        @include media_desktop {
            margin: 0 10px 0;

            button {
                &:before {
                    font-size: 6px;
                }
            }
        }
    }

    @include media_desktop {
        bottom: -28px;
    }
}

.LastButtonContainer {
    margin: 30px auto;
}

.LastButtonNextWord {
    color: var(--color-dim-gray);
    margin-right: px-to-vw(30px);
}
