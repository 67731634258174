@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-tuna);
    padding: 60px 27px 28px 20px;
    width: 100%;
    min-height: 100%;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        padding: 8.148148148148147vh 27px 28px 20px;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Title {
    color: var(--color-white);
    margin: 1.5rem 0 0;

    @include media_tablet {
        margin: 40px 0 23px;
    }

    @include media_desktop {
        font-size: 4.62962962962963vh;
        line-height: 1.28;
        letter-spacing: -2px;
        margin: 2rem 0 0;
    }
}

.TermsOfUsePageDescription {
    color: var(--color-white);
    text-align: center;
    margin-top: 16px;

    @include media_tablet {
        width: 80%;
        margin: 16px auto 0;
    }

    @include media_desktop {
        width: 60%;
        margin: 3.7037037037037033vh auto;
        line-height: 1.8;
    }
}

.Description {
    white-space: pre-line;
    color: var(--color-white);
    text-align: center;
    margin-top: 16px;
}

.ButtonDecline {
    color: var(--color-white);
}

.ButtonAgree {
    background-color: var(--color-black-a30);

    @include media_desktop {
        height: 48px;
    }
}

.Footer {
    margin-top: 1.5rem;
}

.DescriptionContainer {
    text-align: justify;
    word-break: break-word;
}

.HeaderImage {
    @include media_tablet {
        height: 75px;
        width: 63px;
    }

    @include media_desktop {
        width: 7.685185185185185vh;
        height: 9.25925925925926vh;
    }
}

.PopupContainer {
    padding: 30px 27px 20px 28px;

    @include media_tablet {
        display: inline-table;
        background-color: var(--color-white);
        margin: 63px 0;
        padding: 60px 9.5%;

        h2 {
            text-align: left;
        }

        h2::after {
            content: '';
            margin: 40px 0;
            width: 135px;
            height: 1px;
            display: block;
            clear: both;
            background-color: var(--color-rodeo-dust);
        }
    }

    @include media_desktop {
        margin: 8.055555555555555vh 11%;
        padding: 5.360824vh 8%;
    }
}

@include media_tablet {
    .ButtonDecline {
        margin-top: 20px;
    }

    .PopupCommonContainer {
        width: 100%;
        background-color: var(--color-alabaster-lighten);
        min-height: 100%;
    }
}

.FixedContainer {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
}

@include media_desktop {
    .FixedContainer {
        position: relative;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 11;
        transform: translate(0, 0);
    }
}

.TermsOfUsePopupContainer {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    position: absolute;
    height: auto;
}

.TermsOfUsePopupContainer div {
    position: relative;
    height: auto;
    overflow-x: hidden;
}

.TermsOfUsePopupContainer div div {
    height: auto;
    overflow-x: hidden;
}
