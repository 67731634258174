@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-pearl-bush-lighten);
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: px-to-vw(60px);

    @include media_tablet {
        padding-top: px-to-vw-tablet(63px);
    }

    @include media_desktop {
        padding-top: px-to-vh(89px);
    }
}

.Container {
    height: 100%;
}

.Header {
    position: relative;
    z-index: 1;
}

.Subtitle {
    position: relative;
    z-index: 1;
    text-align: center;
}

.Video {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    @include media_tablet {
        max-width: 476px;
    }

    @include media_desktop {
        max-width: 54.07407vh;
    }
}

.Button {
    position: absolute;
    bottom: px-to-vw(50px);
    left: 50%;
    transform: translateX(-50%);
    width: initial;

    span {
        overflow: visible;
        text-overflow: unset;
    }

    @include media_tablet {
        bottom: px-to-vw-tablet(63px);
        padding-left: 25px;
        padding-right: 25px;
    }

    @include media_desktop {
        bottom: px-to-vh(89px);
    }
}
