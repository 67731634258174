@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/mixins';

.Page {
    padding: 60px 16px 60px 17px;
    max-width: 100%;

    @include media_tablet {
        max-width: unset;
    }

    @include media_desktop {
        padding: px-to-vh(121px) 0 0;
        width: 100%;
    }
}

.Container {
    @include media_desktop {
        display: flex;
        justify-content: space-between;
    }
}

.ContentSide {
    overflow: hidden;

    @include media_desktop {
        padding-right: px-to-vh(130px);
        padding-bottom: px-to-vh(55px);
        display: flex;
        flex-direction: column;
    }
}

.ImageSide {
    @include media_desktop {
        position: relative;
        min-width: 510px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

.DesktopImage {
    @include media_desktop {
        max-height: 100%;
    }
}

.IconWrapper {
    @include media_desktop {
        width: 91%;
    }
}

.Icon {
    width: 100%;
    max-width: 59px;
    margin: 0 auto 26px;
    display: block;

    @include media_desktop {
        max-width: 51px;
        margin: 0;
    }
}

.Title {
    color: var(--color-tuna);
    text-align: center;
    margin: 0 auto;
    font-size: 24px;

    @include media_desktop {
        font-size: 35px;
        text-align: left;
        letter-spacing: -2px;
        margin: px-to-vh(40px) 0 0;
        width: 100%;
        line-height: 1.28;
    }
}

.Description {
    margin: 0;
    text-align: center;

    @include media_desktop {
        text-align: left;
        line-height: 1.8;
    }

    &.BTNotSupportedDescription {
        margin-top: px-to-vw(30px);

        @include media_tablet {
            margin-top: px-to-vh-tablet(45px);
        }

        @include media_desktop {
            margin-top: px-to-vh(45px);
        }
    }
}

.BrowserLink {
    margin-top: 0;

    @include txt--regular();
}

.ConnectText {
    text-align: center;

    @include media_desktop {
        text-align: left;
    }
}
