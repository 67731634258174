@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.BatteryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.Battery {
    display: flex;
    align-items: center;
    width: 28px;
    height: 14px;
    border-radius: 5px;
    border: solid 1px var(--color-shark);
    margin-left: 5px;
    padding: 2px;

    @include media_tablet {
        width: 35px;
        height: 16px;
    }

    @include media_desktop {
        width: px-to-vh(47px);
        height: px-to-vh(21px);
    }
}

.BatteryLeft {
    width: 14px;
    height: 8px;
    border-radius: 3px;
    background-color: var(--color-shark);

    @include media_desktop {
        width: px-to-vh(21px);
        height: px-to-vh(12px);
    }
}

.BatteryNib {
    width: 1px;
    height: 5px;
    margin-left: 2px;
    background-color: var(--color-shark);
}
