@import '../../../assets/scss/medias';
@import '../../../assets/scss/mixins';

.ExpansionPanel {
    position: relative;
    width: 100%;
    cursor: pointer;
    user-select: none;
    z-index: 1;

    @include media_desktop {
        min-height: 4.5rem;
        margin-bottom: 0.5rem;
    }
}

.ExpansionPanel::after {
    position: absolute;
    content: '';
    width: 110%;
    height: 1px;
    bottom: 0;
    background-color: var(--color-dove-gray);
    opacity: 0.3;
    right: 50%;
    transform: translateX(50%);
}

.Main {
    position: relative;
    display: flex;
    padding-right: 2.5rem;

    @include media_desktop {
        height: 4rem;
    }
}

.TitleWrapper {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;

    @include media_desktop {
        margin-left: 12px;
        height: 4rem;
    }

    p {
        margin: 0;

        @include media_tablet {
            margin-left: 10px;
        }

        p,
        p font,
        p span font {
            @include txt--regular();
        }
    }
}

.ArrowWrapper {
    position: relative;
    width: 1rem;
    height: 65px;

    @include media_desktop {
        height: 4rem;
    }
}

.Arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    transition: 0.2s ease;

    @include media_tablet {
        width: 16px;
    }
}

.ArrowExpanded {
    transform: translateY(-50%) rotate(180deg);
    transform-origin: center;
    transition: 0.2s ease;
}

.Body {
    width: 100%;
    padding-bottom: 1rem;
}
