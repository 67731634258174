@import '../../../../../assets/scss/medias';

.LockPopupHeader {
    display: flex;
}

.LockPopupHeaderImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: var(--color-black);
    border-radius: 5px;
    color: white;
}

.PopupHeaderTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    @include media_desktop {
        margin-top: 0;
    }
}

.PopupDescription {
    text-align: center;
    margin: 0.5rem 0 0.5rem 0;
}

.LockPopupBodyRow {
    margin: 0.5rem 0;
    text-align: left;
}

.DisconnectPopupBodyRow {
    margin: 1rem 0;
    text-align: center;
}

.LockPopupBodyRow > span {
    font-family: var(--font-bold);
}

.LockTimeSelectors button {
    font-family: var(--font-regular);
    color: var(--color-alto);
    font-size: 4.8vw;
}

.LockTimeSelectors button.Active {
    font-size: 5.33333vw;
    color: var(--color-black);
    border-top: 1px solid var(--color-alto);
    border-bottom: 1px solid var(--color-alto);
    height: 3rem;
}

.TimeSelector {
    padding: 0.5rem 0;
}

.TimeSelectorActive {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--color-alto);
    border-top: 1px solid var(--color-alto);
}

.Button {
    margin-top: 1.5rem;

    @include media_desktop {
        margin-top: 3.7vh;
        margin-bottom: 2vh;
    }
}
