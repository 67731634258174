@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.SettingsOptionsContainer {
    margin-top: -50px;

    @include media_tablet {
        margin-top: -7.2%;
    }
}

.DeviceSettingsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
    padding: 0 27px;
    grid-gap: 20px;

    @include media_tablet {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        width: max-content;
        padding: 0;
    }

    @include media_custom(420px, 600px) {
        max-width: 308px;
        width: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .DeviceSettingsItemContainer {
            margin: 0 auto;
        }
    }

    @include media_custom(601px, 760px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.SpaceAround {
    @include media_tablet {
        justify-content: space-around;
    }
}

.DeviceSettingsItemContainer {
    background-color: var(--color-white);
    border-radius: 3px;
    border: 1px solid var(--color-pearl-bush);
    height: 100px;
    margin: 0 auto;
    max-width: 150px;
    padding: 15px;
    position: relative;
    width: 100%;

    @include media_tablet {
        display: flex;
        flex-direction: column;
        height: 118px;
        justify-content: space-between;
        margin: 0 4px 0 4px;
        max-height: 118px;
        max-width: 150px;
        min-height: 80px;
        min-width: 150px;
        padding: 17px 8px 23px;
        width: 100%;
    }

    @include media_desktop {
        height: px-to-vh(160px);
        margin: 0 px-to-vh(22px) 0 px-to-vh(22px);
        max-height: px-to-vh(160px);
        max-width: 260px;
        min-height: px-to-vh(120px);
        min-width: px-to-vh(260px);
        padding: px-to-vh(24px) px-to-vh(30px) px-to-vh(25px);
    }
}

.DeviceSettingsItemContainer:global(.ica-disabled) {
    border-color: var(--color-alto-second);
}

.DeviceSettingsItemContainer:global(.ica-disabled) img,
.DeviceSettingsItemContainer:global(.ica-disabled) div {
    opacity: 0.4;
}

.DeviceSettingsItemContainer div {
    color: var(--color-tuna);
    font-family: var(--font-bold);
    margin-top: 10px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.5rem;

    @include media_tablet {
        letter-spacing: 2px;
        white-space: pre-line;
    }
}

.DeviceSettingsItemContainer img {
    max-height: 21px;

    @include media_tablet {
        height: 30px;
        max-height: 30px;
    }

    @include media_desktop {
        height: px-to-vh(44px);
        max-height: 44px;
    }
}

.DeviceDiagnosticStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DeviceDiagnosticTitle {
    color: var(--color-tuna);
    margin: 0.5rem 0 0.8rem 0;
    word-break: break-word;

    @include media_tablet {
        margin: 0 0 23px 0;
    }

    @include media_desktop {
        margin: 0 0 2rem 0;
    }
}

.DeviceDiagnosticDescription {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;

    @include media_desktop {
        margin-bottom: 2rem;
    }
}

.FailMessageIcon {
    width: 21px;
    height: 21px;

    @include media_tablet {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
    }

    @include media_desktop {
        top: 40px;
        margin-bottom: 0;
        position: absolute;
    }
}

.FailMessageIcon::after {
    padding-bottom: 2px;
    font-size: px-to-vw(18px);

    @include media_tablet {
        font-size: 27px;
    }

    @include media_desktop {
        font-size: px-to-vh(28px);
    }
}
