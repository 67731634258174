@import '../../../assets/scss/medias';

.NextBtn {
    margin: 2.25rem 0;
}

.Dots {
    position: relative;
    margin-bottom: 3rem;

    &:global(.touch-device li button:hover::before),
    &:global(.touch-device li button:focus::before) {
        opacity: 0.25;
    }

    &:global(.touch-device li.slick-active button::before) {
        opacity: 0.75;
    }

    li {
        margin: 0 10px 0 0;
        width: auto;
        height: auto;

        @include media_tablet {
            margin-right: 18px;
        }

        @include media_desktop {
            margin-right: 15px;
        }

        button {
            width: auto;
            height: auto;
            padding: 0;

            &:before {
                position: relative;
                width: auto;
                height: auto;
            }
        }
    }
}

.CommonNextBtn {
    position: absolute;
    bottom: 0;
}
