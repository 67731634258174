@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.PopupContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    max-height: 100%;
    overflow: auto;
    overscroll-behavior: contain;

    @include media_tablet {
        position: absolute;
        top: 63px;
        left: 50%;
        transform: translate(-50%, 0);
        height: min-content;
    }

    @include media_desktop {
        top: 89px;
    }
}

.OverlayOnPopupClose {
    animation: overlayOnPopupClose 700ms ease forwards;
}

.PopupsContainer {
    @include media_tablet {
        max-width: 624px;
    }
    @include media_desktop {
        max-width: px-to-vh(1170px);
    }
}

.PopupOnClose {
    transform: translateY(150%);
    transition: 700ms ease;

    @include media_tablet {
        transform: translate(-50%, -150%);
    }

    @include media_desktop {
        transform: translateY(-150%);
        left: 0;
    }
}

.PopupTopMargin {
    height: 100px;

    @include media_tablet {
        display: none;
    }
}

.Popup {
    background-color: var(--color-white);
    padding: 32px 27px 30px 28px;
    overscroll-behavior: contain;

    @include media_tablet {
        padding: 32px 62px 30px;
    }

    @include media_desktop {
        padding: 4.5rem 6.75rem 2rem;
    }
}

@media (hover: none) and (pointer: coarse) {
    .PopupTouchCloseLine::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 60px;
        background-color: var(--color-alto-second);
        border-color: var(--color-alto-second);
        border-radius: 1px;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);

        @include media_tablet {
            display: none;
        }
    }
}

.PopupRounded {
    border-radius: 10px 10px 0 0;

    @include media_tablet {
        border-radius: 0;
    }
}

.HeaderIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @include media_tablet {
        margin-bottom: 18px;
    }

    @include media_desktop {
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);
        margin: 0;
    }

    img {
        @include media_tablet {
            width: 30px;
            height: 30px;
        }
    }
}

@keyframes overlayOnPopupClose {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.PopupError {
    text-align: center;

    h2 {
        margin-top: 0.5rem;

        @include media_desktop {
            margin: 0 auto;
            letter-spacing: 0;
        }
    }

    p {
        margin-bottom: 1.5rem;

        @include media_desktop {
            margin-top: px-to-vh(40px);
            margin-bottom: px-to-vh(40px);
        }

        @include media_tablet {
            margin-bottom: 32px;
        }
    }
}
