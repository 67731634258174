@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    display: flex;
    color: var(--color-dove-gray);
    max-width: 100%;
    width: 100%;
    letter-spacing: 3px;
    font-size: px-to-vw(14px);
    font-family: var(--font-medium);

    @include media_tablet {
        background: var(--color-alabaster-lighten);
    }

    .BackButton {
        z-index: 1;

        @include media_tablet {
            left: 16px;
        }
    }

    .LoginPageContainer {
        width: 85.5vw;

        @include media_tablet {
            padding-top: px-to-vh-tablet(63px);
        }

        @include media_desktop {
            padding-top: px-to-vh(90px);
        }

        .SubtitleText {
            text-align: center;
            font-size: px-to-vw(14px);
            color: var(--color-charade);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            width: px-to-vw(320px);
            margin: 3rem auto 0;

            @include media_tablet {
                font-size: 16px;
                margin-top: px-to-vh-tablet(35px);
                max-width: 450px;
                width: 100%;
            }

            @include media_desktop {
                font-size: px-to-vh(20px);
                letter-spacing: 0;
                line-height: 36px;
                margin-top: px-to-vh(45px);
                max-width: 650px;
                width: 100%;
                color: var(--color-tuna);
            }
        }

        .Title {
            display: none;

            @include media_tablet {
                display: block;
                color: var(--color-tuna);
                letter-spacing: 0;
            }

            @include media_desktop {
                margin-top: 6px;
            }
        }

        .LoginForm {
            margin-top: 1.5rem;

            @include media_tablet {
                margin: px-to-vh-tablet(40px) auto 0;
                font-size: 16px;
                background: var(--color-white);
                padding: px-to-vh(33px) px-to-vh(60px) px-to-vh(60px);
                width: px-to-vh-tablet(470px);
            }

            @include media_desktop {
                width: px-to-vh(570px);
                padding: px-to-vh(33px) px-to-vh(60px) px-to-vh(60px);
                margin-top: px-to-vh(40px);
            }

            .ErrorMessage {
                text-align: left;
                color: var(--color-cinnabar);
                margin-top: 0.5rem;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
            }

            .InputLabel {
                margin: 1.35rem 0 0.4rem 0;
                letter-spacing: 0;
                color: var(--color-charade);

                @include media_tablet {
                    font-size: 16px;
                }
            }

            .InputElement {
                font-size: px-to-vw(14px);
                text-align: left;
                height: 46px;
                margin: 0 auto;
                border-color: var(--color-alto-second);

                @include media_tablet {
                    font-size: 16px;
                }

                @include media_desktop {
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--color-charade);
                }
            }

            .InputError {
                border-color: var(--color-cinnabar);
            }

            .ForgotLink {
                letter-spacing: 0;
                color: var(--color-tuna);
                margin-top: 0.65rem;
                display: block;
                font-family: inherit;

                @include media_desktop {
                    font-size: 14px;
                    line-height: 14px;
                    color: var(--color-charade);
                }
            }

            .LoginButton {
                margin: 1.7rem auto 0;

                @include media_desktop {
                    margin-top: px-to-vh(40px);
                }
            }

            .SecondLink {
                text-align: center;
                margin-top: 0.8rem;
                font-size: px-to-vw(11px);
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.45;
                letter-spacing: 2px;

                @include media_tablet {
                    font-size: 12px;
                }

                @include media_desktop {
                    font-size: px-to-vh(15px);
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-family: var(--font-bold);
                    margin-top: px-to-vh(40px);
                    color: var(--color-tuna);
                }
            }

            .RegisterLink {
                font-weight: bold;
                color: var(--color-tuna);

                @include media_tablet {
                    font-size: 14px;
                }

                @include media_desktop {
                    font-size: px-to-vh(15px);
                }
            }
        }
    }
}
