@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Button {
    height: auto;
    left: 16px;
    margin: 0;
    position: absolute;
    top: 15px;
    width: auto;
    padding: 0;
    color: var(--color-tuna);

    @include media_tablet {
        left: 0;
        padding: 0;
        top: 20px;
    }

    @include media_desktop {
        top: px-to-vh(38px);
    }

    span {
        display: none;

        @include media_tablet {
            display: inline;
            font-size: 12px;
            margin-left: 11px;
            margin-top: 1px;
        }

        @include media_desktop {
            margin-left: 12px;
            font-size: 1.48148vh;
        }
    }
}
