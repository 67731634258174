@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.HeaderTitle {
    width: 85%;
    margin: 0 auto;
    white-space: pre-line;

    @include media_desktop {
        max-width: px-to-vh(700px);
    }
}

.Description {
    margin: 0;

    @include media_tablet {
        font-size: 16px;
    }

    @include media_desktop {
        margin: 0 auto;
        max-width: px-to-vh(700px);
    }
}
