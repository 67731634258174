@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.Container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include media_desktop {
        align-items: flex-start;
    }
}

.ButtonContainer {
    cursor: pointer;
    margin-bottom: 20px;
    max-width: 210px;
    position: relative;
}

.Image {
    display: block;
    max-width: 100%;
}

.Text {
    color: var(--color-black);
    font-family: var(--font-regular);
    font-size: 12px;
    left: 65%;
    letter-spacing: 2px;
    margin: 0;
    position: absolute;
    top: 42.5%;
    transform: translate(-65%, -42.5%);
    white-space: nowrap;

    span {
        font-family: var(--font-bold);
    }
}

.DownloadText {
    text-align: center;
    margin-bottom: px-to-vw(24px);

    @include media_desktop {
        margin-bottom: px-to-vh(24px);
        text-align: left;
    }
}
