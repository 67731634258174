@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.ButtonSecondary {
    margin: 0.5rem 0 0;

    @include media_desktop {
        margin-top: px-to-vh(20px);
    }
}
