@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.ButtonDark {
    background-color: var(--color-tuna);
    border: solid 1px var(--color-tuna);
    color: var(--color-white);
    height: 36px;
    margin: 0 auto;
    max-width: 100%;
    padding: px-to-vh(9px) px-to-vh(24px) px-to-vh(12px);
    transition: all 0.8s ease;
    white-space: nowrap;
    width: min-content;

    @include media_desktop {
        height: px-to-vh(48px);
        max-height: 48px;
    }
}

.ButtonDark:hover {
    color: var(--color-white);
}

.ButtonDark span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
