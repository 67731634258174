@import '../../../assets/scss/helpers';
@import '../../../assets/scss/medias';

@mixin dropdown-list-font() {
    font-size: px-to-vh(14px);
    line-height: 1.79;
    color: var(--color-charade);
    user-select: none;
}

.DropdownList {
    position: relative;
    max-width: 140px;
    width: px-to-vh(140px);
    border: 1px solid var(--color-charade-a20);
    max-height: 44px;
    height: px-to-vh(44px);
    z-index: 1;
    border-radius: 3px;
}

.Main {
    padding: px-to-vh(8px) px-to-vh(20px) px-to-vh(7px) px-to-vh(14px);
    cursor: pointer;
    position: relative;
    height: 100%;
    width: 100%;
}

.MainTitle {
    @include dropdown-list-font();
}

.Arrow {
    position: absolute;
    border: 1px solid var(--color-charade);
    border-bottom: none;
    border-left: none;
    top: 45%;
    transform: translateY(-45%) rotate(135deg);
    right: px-to-vh(14px);
    width: 7px;
    height: 7px;
    transition: all 200ms ease;

    &.Rotated {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
    }
}

.Body {
    position: absolute;
    top: 100%;
    height: px-to-vh(176px);
    max-height: px-to-vh(176px);
    width: 100%;
    display: none;
    left: 0;
    overflow: scroll;
    border-bottom: 1px solid var(--color-charade-a20);
    border-top: 1px solid var(--color-charade-a20);

    &.Expanded {
        display: block;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.Item {
    border: 1px solid var(--color-charade-a20);
    padding: px-to-vh(8px) px-to-vh(20px) px-to-vh(7px) px-to-vh(14px);
    background: var(--color-white);
    cursor: pointer;
    width: 100%;
    max-height: 44px;
    height: px-to-vh(44px);

    &:nth-child(odd) {
        border-top: none;
        border-bottom: none;
    }

    @include dropdown-list-font();
}
