@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.Popup {
    @include media_tablet {
        padding: 40px;
    }

    @include media_desktop {
        padding: px-to-vh(60px);
    }
}
