@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.ArrowContainer {
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    margin-right: 0;
}

.Arrow {
    width: 15px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}
