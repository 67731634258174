@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    padding: px-to-vw(40px) px-to-vw(28px) 0 px-to-vw(27px);
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    @include media_tablet {
        background: var(--color-alabaster-lighten);
    }
}

.Icon {
    display: flex;
    justify-content: center;
}

.Title {
    display: none;

    @include media_tablet {
        display: block;
        margin-top: px-to-vh-tablet(36px);
    }

    @include media_desktop {
        margin-top: px-to-vh(36px);
    }
}

.Description {
    text-align: center;
    margin-top: px-to-vw(12px);
    white-space: pre-wrap;

    @include media_tablet {
        margin-top: px-to-vh-tablet(40px);
    }

    @include media_desktop {
        margin-top: px-to-vh(40px);
    }
}

.SelectText {
    text-align: center;
    margin: px-to-vw(30px) 0 px-to-vw(24px);
    font-weight: bold;

    @include media_tablet {
        display: none;
    }
}

.SelectBody {
    display: none;

    @include media_tablet {
        background: var(--color-white);
        width: px-to-vw-tablet(570px);
        max-width: 570px;
        margin: px-to-vw-tablet(59px) auto 0;
        padding: px-to-vw-tablet(36px) px-to-vw-tablet(60px);
        display: block;
    }

    @include media_desktop {
        width: px-to-vh(570px);
        padding: px-to-vh(36px) px-to-vh(60px);
        margin-top: px-to-vh(59px);
    }
}

.ListLabel {
    display: none;

    @include media_tablet {
        display: block;
        font-size: px-to-vh-tablet(14px);
        line-height: px-to-vh-tablet(25px);
        margin-bottom: px-to-vh-tablet(10px);
        color: var(--color-charade);
    }

    @include media_desktop {
        font-size: px-to-vh(14px);
        line-height: px-to-vh(25px);
        margin-bottom: px-to-vh(10px);
    }
}

.ListContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Button {
    margin-top: px-to-vw(30px);

    @include media_tablet {
        margin-top: px-to-vh-tablet(36px);
    }

    @include media_desktop {
        margin-top: px-to-vh(36px);
    }
}
