@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.Container {
    padding: 60px 27px 32px 28px;

    @include media_tablet {
        padding: 0;
    }

    @include media_desktop {
        grid-column: 1;
        grid-row: 1;
    }
}

.Container h2 {
    @include media_desktop {
        margin-top: 0;
    }
}

.ImageContainer {
    margin-top: 1rem;
    height: 180px;

    @include media_tablet {
        height: px-to-vw-tablet(334px);
        max-height: 300px;
    }

    @include media_desktop {
        height: px-to-vh(290px);
        max-height: 289px;
    }
}
