@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    background-color: var(--color-white);
    height: 100%;
    overflow-x: hidden;
    padding: 50px 27px 40px 28px;
    text-align: center;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        display: flex;
        padding: 0 0 0 3.6rem;
    }
}

.Title {
    color: var(--color-revolver-a95);
    font-size: 6.4vw;
    margin-bottom: 0.15rem;
    text-align: center;

    @include media_tablet {
        width: 60%;
        margin: 0 auto 8px;
        letter-spacing: -2px;
    }

    @include media_desktop {
        letter-spacing: 0;
        margin-bottom: 4rem;
        position: relative;
        text-align: left;
        width: 80%;
        margin-left: 0;
    }

    &:after {
        @include media_desktop {
            background-color: var(--color-rodeo-dust);
            bottom: -2rem;
            content: '';
            height: 1px;
            left: 0;
            min-width: 80px;
            position: absolute;
            width: 7.031vw;
        }
    }
}

.Description {
    display: inline-flex;
    font-family: var(--font-medium);
    margin-bottom: 0;
    text-align: center;

    @include media_tablet {
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 28px;
    }

    @include media_desktop {
        text-align: left;
        width: 100%;
        margin: 0 0 1rem;
    }

    &:last-child {
        margin-top: 0.5rem;

        @include media_tablet {
            margin-top: 0;
        }
    }
}

.TextContainer {
    @include media_desktop {
        display: flex;
        flex-direction: column;
        min-width: 650px;
        padding: 11.7% 2rem 90px 0;
        width: 39.5%;
    }
}

.ImageContainer {
    margin: 1rem 0;
    width: 100%;
    position: relative;
    height: 206px;

    @include media_tablet {
        width: 100%;
        height: px-to-vw-tablet(467px);
        margin: 35px 0 45px;
    }

    @include media_desktop {
        height: auto;
        margin: 0;
        max-width: 1161px;
        padding-top: 1rem;
        width: px-to-vh(1361px);
    }

    img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include media_tablet {
            min-width: 100%;
        }

        @include media_desktop {
            position: unset;
            max-width: 100%;
            transform: none;
        }
    }
}

.YAP {
    @include media_desktop {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    img {
        @include media_desktop {
            max-height: 852px;
        }
    }
}

.ModifiedImage {
    position: relative;
    right: 27px;
}

.LogoutButton {
    width: 100%;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    margin: px-to-vw(20px) 0;

    @include media_desktop {
        max-width: 100%;
        width: px-to-vh(320px);
        margin: px-to-vh(45px) 0;
    }
}

.Button {
    @include media_desktop {
        margin: 4rem 0 0;
    }
}

.YAPImageContainer {
    height: auto;

    img {
        position: unset;
        transform: none;

        @include media_tablet {
            min-width: auto;
            max-height: 467px;
        }
    }
}

@media (max-width: 360px) {
    .ModifiedImage {
        right: 0;
    }
}
