@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.SkipButton {
    color: var(--color-white);
}

.SliderWrapper {
    @include media_tablet {
        margin: 0 auto;
        max-width: 632px;
        width: px-to-vh(632px);
    }

    @include media_desktop {
        max-width: 1476px;
        width: px-to-vh(1476px);
    }
}

.SliderItemWrapper {
    width: 100vw;
    height: 100vh;
    outline: none;

    .SliderItem {
        height: 100%;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media_tablet {
            width: 100%;
        }

        img {
            width: 100vw;
            height: 100vh;
        }

        .SliderItemBackground {
            height: 100vh;
            width: 100vw;
            background-size: cover;

            @include media_tablet {
                max-width: 632px;
                width: px-to-vh(632px);
                max-height: 664px;
                height: px-to-vh(664px);
            }

            @include media_desktop {
                max-width: 1476px;
                width: px-to-vh(1476px);
                max-height: 730px;
                height: px-to-vh(730px);
            }
        }

        .SliderItemText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--color-white);
            text-align: center;
            width: 80%;

            @include media_tablet {
                font-size: px-to-vh-tablet(20px);
                line-height: px-to-vh-tablet(36px);
            }

            @include media_desktop {
                font-size: px-to-vh(20px);
                line-height: px-to-vh(36px);
                max-width: 520px;
                width: px-to-vh(520px);
            }
        }
    }
}

.Button {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -65%);
    background: var(--color-white);
    color: var(--color-nepal);
    border: 1px solid var(--color-tuna);
    width: 180px;
}

.Button:hover {
    color: var(--color-nepal);
}

.Dots {
    &:global(.slick-dots) {
        position: absolute;
        left: 50%;
        height: 20px;
        margin: 0;
        top: 55%;
        transform: translate(-50%, -55%);
        bottom: unset;

        @include media_tablet {
            top: unset;
            bottom: 10%;
            transform: translate(-50%, -10%);
        }

        li:global(.slick-active) {
            button {
                background: var(--color-white);

                @include media_tablet {
                    background: var(--color-tuna);
                }

                &:before {
                    display: none;
                }
            }
        }

        li {
            button {
                width: 10px;
                height: 10px;
                background: var(--color-black);
                border-radius: 50%;
                border: 2px solid var(--color-white);

                @include media_tablet {
                    background: var(--color-dove-gray);
                    border: none;
                    width: 6px;
                    height: 6px;
                }

                &:before {
                    display: none;
                }
            }
        }
    }
}

.Hidden {
    display: none;
}
