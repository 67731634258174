@import '../../../assets/scss/medias';

.Page {
    padding: 50px 27px 20px 28px;
    background-color: var(--color-hint-of-red);
    height: 100%;
    text-align: center;
    overflow-x: hidden;

    @include media_tablet {
        width: 100%;
        padding: 0;
    }
}

.ConnectionLost {
    opacity: 0.5;
}

.Container {
    position: initial;

    @include media_tablet {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}

.CommonLoaderContainer {
    margin-top: 10px;

    @include media_tablet {
        display: block;
        margin-top: 63px;
        margin-bottom: 40px;
    }

    @include media_desktop {
        margin-top: 87px;
    }
}
