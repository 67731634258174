.PopupContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Popup {
    background-color: var(--color-white);
    height: 100%;
    overflow-x: auto;
}
