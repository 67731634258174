@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.DeviceInfoContainer {
    align-items: center;
    border-bottom: 1px solid var(--color-mercury);
    border-top: 1px solid var(--color-mercury);
    cursor: pointer;
    display: flex;
    height: 70px;
    margin: 0 auto;
    padding: 0 35px 0 20px;
    position: relative;
    top: -1px;
    width: 95%;

    @include media_tablet {
        height: 100px;
        padding-left: 22px;
    }

    @include media_desktop {
        cursor: unset;
        height: auto;
        padding: 2rem 1.5rem;
        width: 100%;
    }

    &:after {
        content: '';
        position: absolute;
        right: 20px;
        top: 25px;
        border: 2px solid var(--color-charade-a60);
        border-top: none;
        border-right: none;
        height: 10px;
        width: 10px;
        transform: rotate(-45deg);

        @include media_tablet {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            transform-origin: center;
        }

        @include media_tablet {
            display: none;
        }
    }

    &:first-child {
        top: 0;

        @include media_desktop {
            display: flex;
            flex-direction: row;
            height: 100px;
            padding: 0;
        }
    }
}

.Expanded {
    &:after {
        transform: rotate(135deg);
        top: 32px;

        @include media_tablet {
            top: 50%;
            transform: translateY(-50%) rotate(135deg);
        }

        @include media_desktop {
            display: none;
        }
    }
}

.DeviceNameTitle {
    font-family: var(--font-bold);
    font-size: 11px;
    letter-spacing: 2px;
    min-width: 130px;
    max-width: 130px;

    @include media_tablet {
        letter-spacing: 3px;
        max-width: 100%;
        white-space: nowrap;
        font-size: 14px;
    }

    @include media_desktop {
        font-size: 1.64vh;
        margin-left: 1.5rem;
    }
}

.IsPackageAvailable .DeviceName div::after {
    content: '';
    position: absolute;
    right: 0;
    top: 14px;
    transform: translate(0, -50%);
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: var(--color-mariner);

    @include media_tablet {
        top: 14px;
    }

    @include media_desktop {
        top: px-to-vh(18px);
    }
}

.DeviceName {
    position: relative;
    display: inline-block;
    max-width: 130px;
    min-width: 110px;
    font-size: 14px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include media_tablet {
        margin: 0 0 0 15px;
        font-size: 16px;
        display: block;
    }

    @include media_desktop {
        margin-left: 2rem;
        max-width: unset;
        font-size: unset;
    }
}

.DeviceName div {
    position: relative;
    padding-right: 8px;

    @include media_tablet {
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 6px;
    }
}

.ButtonContainer {
    margin-top: 20px;
    padding: 0 20px;
}

.DeviceDetailsContainer {
    width: 95%;
    margin: 0 auto;
    background-color: var(--color-alabaster);
    padding: 20px;

    @include media_tablet {
        padding: 29px;
    }

    @include media_desktop {
        width: 100%;
        padding: 2rem 1.5rem;
    }
}

.DeviceDetailsItem {
    display: flex;

    @include media_desktop {
        line-height: 28px;
    }
}

.DeviceInfoTitle {
    max-width: 130px;
    min-width: 105px;
    width: 100%;

    @include media_desktop {
        margin-right: 67px;
    }
}

.DeviceInfoRow {
    color: var(--color-dove-gray);

    @include media_tablet {
        margin-left: px-to-vw-tablet(25px);
    }

    @include media_desktop {
        margin-left: 0;
    }
}

.DeviceDetailsItem {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin-left: 15px;
    }
}

.FwuBtn {
    margin-top: 40px;
    margin-bottom: 30px;

    @include media_tablet {
        width: 34vw;
        margin-bottom: 3px;
    }

    @include media_desktop {
        margin: auto 0 auto auto;
        width: 300px;
        height: 49px;
    }
}

.InfoContainer {
    margin: -1px;

    @include media_desktop {
        width: 700px;
        grid-column: 2;
    }
}

.ButtonDisabled {
    opacity: 0.4;
}
