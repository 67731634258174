@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    background-color: var(--color-pearl-bush);
    overflow: hidden;
    width: 100%;
    min-height: 100%;
}

.Container {
    padding: 60px 27px 60px 28px;
    min-height: 100%;

    @include media_desktop {
        padding-top: px-to-vh(84px);
    }
}

.ImageContainer {
    height: 50vh;
    margin: 1.5rem 0 1.5rem;

    @include media_tablet {
        height: 410px;
        margin: 55px auto 19px;
    }

    @include media_desktop {
        height: px-to-vh(596px);
        margin: 2.5rem auto 3rem;
        width: 50%;
    }

    &.Activation {
        @include media_desktop {
            height: px-to-vh(434px);
            min-height: 430px;
            margin: 2.25rem auto 3.25rem;
        }
    }
}

.Header {
    color: var(--color-charade);
    margin: 0;
    word-break: break-word;

    @include media_tablet {
        margin: 1px auto 0;
        width: 75%;
    }

    @include media_desktop {
        width: 80%;
        max-width: 700px;
        margin: 0 auto;
    }
}

.ButtonSecondary {
    @include media_tablet {
        margin-top: 10px;
    }

    @include media_desktop {
        margin-top: 1rem;
    }
}

.ActivationDescription {
    text-align: center;

    @include media_tablet {
        max-width: 700px;
        margin: 0.5rem auto 0;
        font-size: 16px;
        line-height: 1.75;
    }

    @include media_desktop {
        margin: 1.2rem auto 0;
        font-size: px-to-vh(20px);
        line-height: 1.8;
    }
}
