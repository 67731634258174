$fontPath: '../fonts/';

@mixin font-face($family, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$fontPath}#{$family}.woff2') format('woff2'), url('#{$fontPath}#{$family}.woff') format('woff');
        font-weight: $weight;
        font-style: $style;
    }
}

@include font-face('NotoSans-Bold');
@include font-face('NotoSans-Light');
@include font-face('NotoSans-Medium');
@include font-face('NotoSans-Regular');
