@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    background-color: var(--color-tuna);
    min-height: 100%;
    min-width: 100%;
    padding: 2.5rem 1.5rem;
    z-index: 11;
    top: 0;
    left: 0;
    position: inherit;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }
}

.Container {
    @include media_tablet {
        max-width: 71%;
    }

    @include media_desktop {
        min-width: 400px;
        max-width: 35%;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Image {
    width: 1.5rem;
    margin-bottom: auto;
    margin-top: auto;

    @include media_tablet {
        height: 38px;
        width: 38px;
    }

    @include media_desktop {
        height: px-to-vh(50px);
        width: px-to-vh(50px);
        margin: 0 1rem 0 0;
    }
}

.ImageSmall {
    @include media_tablet {
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }

    @include media_desktop {
        height: px-to-vh(40px);
        width: px-to-vh(40px);
        margin-right: 1.5rem;
    }
}

.ImageTextContainer {
    display: flex;

    @include media_tablet {
        width: 79%;
        margin: 0 auto;
    }

    @include media_desktop {
        margin-bottom: 1.5rem;
        align-items: center;
        width: auto;
    }
}

.Title {
    color: var(--color-white);
    margin-bottom: 1rem;

    @include media_tablet {
        width: 55%;
        margin: 0 auto 25px;
        letter-spacing: -2px;
    }

    @include media_desktop {
        margin: 0 0 2rem;
        width: auto;
    }
}

.Description {
    color: var(--color-white);
    text-align: center;

    @include media_tablet {
        width: 90%;
        margin: 0 auto 20px;
    }

    @include media_desktop {
        margin-bottom: 2.5rem;
        line-height: 1.8;
        width: 100%;
    }

    span {
        color: var(--color-rodeo-dust);
        font-family: var(--font-bold);
        cursor: pointer;
        text-decoration: underline;
    }
}

.Text {
    margin-left: 10px;
    color: var(--color-white);

    @include media_tablet {
        margin-top: 10px;
    }

    @include media_desktop {
        margin: 0;
    }
}

.ButtonDecline {
    color: var(--color-white);

    @include media_desktop {
        margin-top: 1rem;
    }
}

.ButtonAgree {
    background-color: var(--color-black-a30);
}

.Footer {
    margin-top: 1.5rem;
}
