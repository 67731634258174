@import '../../../assets/scss/medias';

.UpdateFWContainer {
    width: 100%;
    top: 0;
    background: var(--color-white);
    text-align: center;
    padding: 50px 27px 20px 28px;

    @include media_desktop {
        padding-top: 77px;
    }
}

.UpdateFWContainer h2 {
    @include media_desktop {
        letter-spacing: 0;
        margin-bottom: 37px;
    }
}

.NoteTxt {
    padding: 5px;
    line-height: 1.71;

    @include media_desktop {
        max-width: 900px;
        margin: 0 auto;
    }
}

.NoteTxt span {
    font-weight: bold;
}
