@import '../../../../../../assets/scss/helpers';
@import '../../../../../../assets/scss/medias';

.DescriptionIcon {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin: 8px auto -7px;
    justify-content: center;
    flex-wrap: wrap;

    @include media_tablet {
        flex-wrap: unset;
    }

    @include media_desktop {
        margin: 35px 0 0;
        line-height: 1.8;
        justify-content: unset;
    }

    &.BTNotSupported {
        margin: 0;

        @include media_tablet {
            margin-top: px-to-vh-tablet(25px);
        }

        @include media_desktop {
            margin-top: px-to-vh(25px);
        }
    }

    &.MarginTopNull {
        margin-top: 0;
    }
}
