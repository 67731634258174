@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.CheckedImage {
    display: flex;
    margin: px-to-vw(40px) auto 0;
    height: px-to-vw(96px);
    max-height: 96px;

    @include media_desktop {
        margin: px-to-vh(77px) auto 0;
        height: px-to-vh(120px);
        max-height: 120px;
    }
}

.LegalText {
    white-space: pre-wrap;
    max-width: px-to-vw(320px);
    font-size: px-to-vw(14px);
    letter-spacing: 0;
    margin: px-to-vw(15px) auto px-to-vw(20px);

    @include media_tablet {
        font-size: px-to-vh-tablet(18px);
        max-width: 550px;
        margin: px-to-vh-tablet(20px) auto px-to-vh-tablet(25px);
    }

    @include media_desktop {
        line-height: 36px;
        margin: px-to-vh(40px) auto px-to-vh(45px);
        font-size: px-to-vh-tablet(20px);
        max-width: 900px;
    }
}

.LinkText {
    color: var(--color-tuna);
}

.Title {
    display: none;
    letter-spacing: -2px;
    color: var(--color-tuna);
    margin: px-to-vw(16px) auto 0;

    @include media_tablet {
        display: block;
        margin: px-to-vh-tablet(20px) auto 0;
        font-size: px-to-vh(40px);
    }

    @include media_desktop {
        margin: px-to-vh(36px) auto 0;
        font-size: px-to-vh(50px);
    }
}
