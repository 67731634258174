@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.BackgroundImage {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    background-size: cover;
    background-position: center;
}

.ValidateImage {
    height: px-to-vw(96px);
    max-height: 96px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include media_desktop {
        height: px-to-vh(120px);
        max-height: 120px;
        top: 39%;
    }
}

.TextContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 46%;
    width: 85%;
    max-width: 320px;
    max-height: 36px;
    color: var(--color-white);
    font-size: px-to-vw(24px);
    font-family: var(--font-light);

    @include media_tablet {
        max-width: 360px;
        max-height: 46px;
        font-size: 40px;
    }

    @include media_desktop {
        top: 52%;
        font-size: px-to-vh(50px);
        max-width: 1170px;
        max-height: 64px;
    }
}
