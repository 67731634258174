@import '../../../../../../../assets/scss/medias';
@import '../../../../../../../assets/scss/helpers';

.MultipleDeviceCarouselDots {
    position: relative;
    bottom: -5%;
    margin-bottom: 0;

    @include media_tablet {
        position: relative;
        margin-bottom: 0;
        bottom: 18px;
        margin-top: 50px;
    }

    @include media_desktop {
        margin-top: px-to-vh(75px);
    }

    li {
        button {
            &:before {
                font-size: px-to-vw(5px);
                width: 10px;
                height: 10px;
                line-height: 12px;

                @include media_tablet {
                    font-size: 6px;
                }

                @include media_desktop {
                    font-size: px-to-vh(6px);
                }
            }
        }
    }
}

.SliderButton {
    @include media_desktop {
        top: 39.35vh;
    }
}

.Slider {
    &:global(.multiple-devices-slider .slick-list) {
        max-width: 1170px;
        margin: 0 auto;

        @include media_tablet {
            max-width: 715px;
        }

        @include media_desktop {
            max-width: 1170px;
        }
    }

    &:global(.multiple-devices-slider .slick-list .ica-slick-track-centered) {
        @include media_tablet {
            transform: translate3d(25%, 0px, 0px) !important;
        }

        @include media_desktop {
            transform: translate3d(32%, 0px, 0px) !important;
        }
    }

    &:global(.multiple-devices-slider .slick-slide) {
        @include media_tablet {
            transform: scale(0.8);
            transition: all 0.2s ease;
        }

        h2 {
            @include media_tablet {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0 auto;
            }

            @include media_tablet {
                max-width: 220px;
            }

            @include media_desktop {
                max-width: 280px;
            }
        }
    }

    &:global(.multiple-devices-slider .slick-center) {
        @include media_tablet {
            transition: all 0.2s ease;
            transform: scale(1);
        }

        h2 {
            @include media_tablet {
                max-width: 100%;
            }
        }
    }
}
