@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-athens-gray);
    padding: 20px;
    min-height: 100%;

    @include media_tablet {
        width: 100%;
    }

    @include media_desktop {
        padding-top: 4.5rem;
    }

    button {
        text-decoration: none;
    }
}

.Container {
    @include media_desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.Header {
    display: flex;
    align-items: center;
    position: relative;

    @include media_tablet {
        width: 100vw;
        height: 336px;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    @include media_desktop {
        width: auto;
    }
}

.HeaderText {
    font-size: 6.4vw;

    @include media_tablet {
        font-size: 32px;
        max-width: 624px;
        margin: 0 auto;
    }

    @include media_desktop {
        width: 100%;
        font-size: 2.5rem;
        line-height: 3.2rem;
        letter-spacing: -2px;
        max-width: unset;
        margin: 0;
    }

    img {
        width: 125px;
    }

    span {
        font-family: var(--font-bold);
        height: 100%;

        @include media_desktop {
            font-family: var(--font-regular);
        }
    }
}

.HeaderTextImage {
    height: 95px;
    margin-top: 0.5rem;

    @include media_desktop {
        margin-top: 0.8rem;
        height: unset;

        img {
            width: 228px;
        }
    }
}

.HeaderImage {
    height: 100%;
    position: relative;
    right: -20px;

    @include media_tablet {
        position: absolute;
        right: 0;
    }

    img {
        height: 330px;
    }
}

.Body {
    button {
        width: 80%;
        margin: 0 auto;
    }
}

.BodyTitle {
    font-size: px-to-vw(24px);
    line-height: px-to-vw(32px);
    text-align: center;

    @include media_tablet {
        font-size: px-to-vh-tablet(34px);
        line-height: normal;
    }
}

.Steps {
    margin: 0.5rem 0;
}

.SettingsImage {
    display: block;
    max-width: 442px;
    margin: 0 auto;
}

.DownloadButtonContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include media_tablet {
        margin-top: 50px;
    }
}

@include media_desktop {
    .DownloadDesktop {
        display: block;
        width: 75%;
        margin-top: 4.5rem;
    }

    .DownloadText {
        color: var(--color-tuna);
        margin-bottom: 0.5rem;
    }
}

.ButtonDark {
    width: 238px;
    height: 36px;
    margin-bottom: 20px;

    @include media_tablet {
        width: auto;
    }
}
