@import '../../../../../assets/scss/helpers';
@import '../../../../../assets/scss/medias';

.FWUProgressContainer {
    width: 100%;
    height: 300px;
    position: relative;
    margin: 25px auto 25px;

    @include media_desktop {
        height: px-to-vh(475px);
        max-height: 475px;
        margin-top: 70px;
    }
}

.ImageDeviceContainer {
    width: 230px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    min-width: 60px;
    height: 270px;

    @include media_desktop {
        height: px-to-vh(470px);
        width: px-to-vh(400px);
        max-width: 400px;
        max-height: 470px;
    }
}

.ImageDeviceContainerBig {
    @include media_tablet {
        height: 347px;
    }

    @include media_desktop {
        height: px-to-vh(434px);
    }
}

.ImageDeviceContainerMedium {
    @include media_tablet {
        height: 270px;
    }
    @include media_desktop {
        height: px-to-vh(433px);
    }
}

.ImageDeviceContainerSmall {
    height: px-to-vh(365px);
}

.Loader {
    width: 100%;

    @include media_desktop {
        min-width: px-to-vh(475px);
        min-height: px-to-vh(461px);
        max-width: 475px;
        max-height: 461px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
