.Wrapper {
    :global(.rmc-multi-picker) {
        height: 145px;

        :global(.rmc-picker) {
            height: 145px;

            &-content {
                :global(.rmc-picker-item) {
                    color: var(--color-charade-a20);
                    transition: all 200ms ease;

                    &-selected {
                        color: var(--color-tuna);
                    }
                }
            }
        }
    }
}
