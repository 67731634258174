@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.Slider {
    position: relative;
    z-index: 1;
}

.SliderContent {
    height: 100vh;
    outline: none;

    @include media_desktop {
        padding-top: px-to-vh(90px);
    }
}

.SliderHeader {
    margin-top: 60px;

    @include media_desktop {
        margin-top: 0;
    }
}

.SliderDescription {
    text-align: center;
    padding: 0 10px;
}

.Dots {
    position: absolute;
    bottom: 7vh;
    z-index: -1;

    @include media_tablet {
        bottom: 3vh;
    }

    @include media_desktop {
        bottom: 7vh;
    }
}
