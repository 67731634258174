.Header {
    text-align: center;
    color: var(--color-charade);
    margin: 60px 20px 0;
    line-height: 1.33;
}

.WelcomeText {
    text-align: center;
    margin-top: 10px;
    padding: 0 20px;
    line-height: 1.86;
}

.HeaderImg {
    width: 100%;
    margin-top: 30px;
}

.ButtonContainer {
    margin: 20px;
}

.ButtonContainer a {
    margin: 0 auto;
    width: 60%;
    color: var(--color-nepal);
}
