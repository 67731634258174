@import '../../../assets/scss/medias';

.HeaderContainer {
    position: absolute;
}

.Page {
    height: 100%;
    background-color: var(--color-pearl-bush-lighten);
    position: relative;
    overflow: hidden;
    width: 100%;
}

.SliderVideo {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

    @include media_tablet {
        max-width: 576px;
    }

    @include media_desktop {
        max-width: 54.07407vh;
    }
}

.PairingLoader {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 50%;
    transform: translate(50%, 50%);
    animation: pairingLoader 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;

    &,
    & span::before {
        width: 46.01851vh;
        height: 46.01851vh;
    }

    @include media_desktop {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-delay: unset;
    }
}

.PageLoader {
    width: 300px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    @include media_desktop {
        width: 54.07407vh;
        height: 54.07407vh;
    }
}

.SliderBlocker {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
}

@keyframes pairingLoader {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
