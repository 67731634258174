$viewport-width: 375;
$viewport-height: 1080;
$viewport-width-tablet: 768;
$viewport-height-tablet: 1024;

@function px-to-vw($px) {
    $vw-context: $viewport-width * 0.01 * 1px;
    @return ($px / $vw-context) * 1vw;
}

@function px-to-vh($px) {
    $vh-context: $viewport-height * 0.01 * 1px;
    @return ($px / $vh-context) * 1vh;
}

@function px-to-vw-tablet($px) {
    $vw-context: $viewport-width-tablet * 0.01 * 1px;
    @return ($px / $vw-context) * 1vw;
}

@function px-to-vh-tablet($px) {
    $vh-context: $viewport-height-tablet * 0.01 * 1px;
    @return ($px / $vh-context) * 1vh;
}
