@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.HeaderContainer {
    text-align: center;
    margin-top: 30px;
}

.Image {
    width: 100%;
    height: 30%;
}

.TutorialListTitle {
    color: var(--color-tuna);
    max-width: 195px;
    max-height: 64px;
    margin: 2.9rem auto 2.5rem;
    font-size: 24px;
    letter-spacing: 0;

    @include media_tablet {
        max-width: 624px;
        max-height: 42px;
        letter-spacing: -2px;
        font-size: 32px;
        margin: 65px auto 24px;
    }

    @include media_desktop {
        max-width: px-to-vh(1170px);
        max-height: px-to-vh(64px);
        font-size: px-to-vh(50px);
        margin: px-to-vh(89px) auto px-to-vh(45px);
    }
}

.TutorialListSubtitle {
    color: var(--color-dove-gray);
    text-transform: uppercase;
    max-width: 320px;
    max-height: 34px;
    margin: 0 auto 1rem;
    letter-spacing: 3px;
    font-size: 12px;
    font-family: var(--font-medium);

    @include media_tablet {
        max-width: 532px;
        max-height: 19px;
        font-size: 14px;
        margin: 0 auto;
    }

    @include media_desktop {
        max-width: px-to-vh(1170px);
        max-height: px-to-vh(64px);
        font-size: px-to-vh(16px);
    }
}
