@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.PageLoaderContainer {
    border-radius: 50%;
    border: 1px solid var(--color-swiss-cofee);
    height: 300px;
    width: 300px;
    z-index: 1;
    margin: 24px auto 30px;

    @include media_tablet {
        margin: 32px auto 46px;
    }

    @include media_desktop {
        margin: 24px auto;
    }
}

.PageLoader {
    //animation: PageLoaderAnim1 3s linear infinite;
    display: block;
    height: 260px;
    left: 19px;
    position: relative;
    top: 19px;
    width: 260px;

    &:after {
        border-radius: 50%;
        border: 2px solid var(--color-swiss-cofee);
        box-sizing: border-box;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    span {
        animation: PageLoaderAnim2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
        bottom: 0;
        box-sizing: border-box;
        clip: rect(130px, 260px, 260px, 0);
        display: block;
        height: 100%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        overflow: hidden;

        &:before {
            content: '';
            animation: PageLoaderAnim3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top: 2px solid #7c7878;
            bottom: 0;
            box-sizing: border-box;
            display: block;
            height: 260px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 260px;
        }
    }
}

.EmptyPageLoader {
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

@keyframes PageLoaderAnim1 {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes PageLoaderAnim2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes PageLoaderAnim3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.PageLoaderMediumContainer {
    height: 240px;
    overflow: hidden;
    position: relative;
    top: 27%;
    width: 240px;

    @include media_tablet {
        height: 324px;
        width: 324px;
        transform: translate(0%, 0%);
        position: relative;
        left: 0;
    }

    @include media_desktop {
        height: px-to-vh(432px);
        width: px-to-vh(432px);
    }
}

.PageLoaderMedium {
    height: 200px;
    width: 200px;

    @include media_tablet {
        height: 270px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 270px;
    }

    @include media_desktop {
        height: px-to-vh(360px);
        width: px-to-vh(360px);
    }

    span {
        clip: rect(100px, 400px, 400px, 0);

        &:before {
            width: 200px;
            height: 200px;

            @include media_tablet {
                width: 270px;
                height: 270px;
            }

            @include media_desktop {
                width: px-to-vh(360px);
                height: px-to-vh(360px);
            }
        }
    }
}

.PageLoaderBigContainer {
    @include media_tablet {
        width: 438px;
        height: 438px;
    }

    @include media_custom(420px, 450px) {
        width: 338px;
        height: 338px;
    }

    @include media_desktop {
        height: px-to-vh(584px);
        width: px-to-vh(584px);
    }
}

.PageLoaderBig {
    @include media_tablet {
        width: 380px;
        height: 380px;
        left: 29px;
        top: 29px;
    }

    @include media_custom(420px, 450px) {
        width: 295px;
        height: 295px;
        left: 20px;
        top: 20px;
    }

    @include media_desktop {
        height: px-to-vh(500px);
        width: px-to-vh(500px);
        left: px-to-vh(40px);
        top: px-to-vh(40px);
    }
    span {
        clip: rect(100px, 400px, 900px, 0);

        &:before {
            @include media_tablet {
                width: 380px;
                height: 380px;
            }

            @include media_custom(420px, 450px) {
                width: 295px;
                height: 295px;
            }

            @include media_desktop {
                height: px-to-vh(500px);
                width: px-to-vh(500px);
            }
        }
    }
}

.PageLoaderSmallContainer {
    margin-top: 51px;
    height: px-to-vh(491px);
    width: px-to-vh(491px);
}

.PageLoaderSmall {
    height: px-to-vh(420px);
    width: px-to-vh(420px);
    left: px-to-vh(34px);
    top: px-to-vh(34px);

    span {
        clip: rect(100px, 400px, 900px, 0);

        &:before {
            height: px-to-vh(420px);
            width: px-to-vh(420px);
        }
    }
}

.PageLoaderStopAnimation {
    span {
        clip: rect(0px, 0px, 0px, 0);
    }
}
