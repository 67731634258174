@import '../../../assets/scss/medias';

.App {
    color: var(--color-black);
    display: flex;
    font-family: var(--font-regular);
    justify-content: center;
    min-height: 100%;
    position: relative;
}

.ComponentWrapper {
    display: flex;
    justify-content: center;
    min-height: 568px;
    width: 100%;

    @include media_desktop {
        min-height: 695px;
    }
}

.Hidden {
    display: none;
}

@media only screen and (max-width: 420px) {
    .AppContainer {
        max-width: none;
    }
}
