@import '../../../assets/scss/medias';

.Page {
    padding: 60px 27px 20px 28px;
    background-color: var(--color-hint-of-red);
    height: 100%;
    text-align: center;

    @include media_tablet {
        width: 100%;
        padding: 0;
    }
}

.HeaderTitle {
    width: 70%;
    margin: 0 auto;
}

.Description {
    margin-top: 0;
}

.ConnectionLost {
    opacity: 0.5;
}

.Container {
    display: contents;

    @include media_tablet {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 63px;
        padding-bottom: 79px;
    }

    @include media_desktop {
        padding-top: 87px;
        padding-bottom: 79px;
    }
}

.CommonLoaderContainer {
    @include media_tablet {
        display: block;
        margin-top: 63px;
        margin-bottom: 79px;
    }
    @include media_desktop {
        margin-top: 87px;
    }
}

.DeviceActivationPageContainer {
    @include media_tablet {
        padding-top: 63px;
    }
    @include media_desktop {
        padding-top: 87px;
    }
}

.ActivetionSkipBtn {
    position: absolute;
}
