@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Arrow {
    background-color: var(--color-black-a10);
    cursor: pointer;
    display: none;
    height: 7.49999vh;
    left: 0;
    min-height: 30px;
    min-width: 35px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6.2037vh;
    z-index: 1;

    @include media_desktop {
        display: block;
    }

    &:hover {
        background-color: var(--color-black-a10);
    }

    &:global(.slick-disabled) {
        cursor: default;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-25%, -50%) rotate(-45deg);
        transform-origin: center;
        border: 1px solid var(--color-tuna);
        border-right: none;
        border-bottom: none;
        width: px-to-vh(29px);
        height: px-to-vh(29px);
        background-image: none;
    }
}

.RightArrow {
    left: unset;
    right: 0;
}

.RightArrow::before {
    transform: translate(-75%, -50%) rotate(135deg);
}
