@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    background-color: var(--color-pearl-bush);
    text-align: center;
    transition: background-color 0.5s;
    min-height: 100%;
    width: 100%;
    padding-bottom: 30px;

    & * {
        outline: none;
        backface-visibility: hidden;
    }
}

.DeviceList {
    padding-top: 2.5rem;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        height: 100%;
        padding: px-to-vh(87px) 0 0;
    }
}

.AddDeviceButton {
    font-weight: bold;

    @include media_tablet {
        font-family: var(--font-medium);
    }

    @include media_desktop {
        font-family: var(--font-bold);
    }
}

.FailedPopupTitle {
    color: var(--color-tuna);
}

.FailedPopupDescription {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
}

.SettingsButton {
    margin: 0.5rem auto 2.1rem;

    @include media_desktop {
        margin: px-to-vh(10px) auto px-to-vh(15px);
    }
}

.SettingsButtonUnavailable {
    opacity: 0.5;
}

.PairingButton {
    margin-top: 0.5rem;
    margin-bottom: 2.05rem;

    @include media_desktop {
        margin: px-to-vh(40px) auto px-to-vh(41px);
        padding: px-to-vh(9px) px-to-vh(28px) px-to-vh(12px);
    }
}

.PlusSymbol {
    font-size: 4vw;
    position: relative;
    top: -1px;
    margin-right: 7px;

    @include media_tablet {
        font-size: 2vh;
    }
}
