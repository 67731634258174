@import '../scss/medias';

.ica {
    * {
        box-sizing: border-box;
    }
}

#root {
    min-height: 568px;

    @include media_desktop {
        min-height: 100%;
    }
}
