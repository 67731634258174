@import '../../../../../assets/scss/medias';
@import '../../../../../assets/scss/helpers';

.MainContainer {
    width: 100%;
    overflow-y: scroll;

    @include media_tablet {
        width: 75%;
        border: solid 2px var(--color-charade);
        border-top: none;
        background-color: var(--color-white);
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 12.5%;
        max-height: 223px;
        max-width: 380px;
    }

    @include media_desktop {
        max-height: px-to-vh(253px);
        max-width: px-to-vh(450px);
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.TableCellContainer {
    width: 100%;

    @include media_tablet {
        flex-direction: row;
        border-bottom: 1px solid var(--color-dove-gray-a30);
        border-left: none;
        border-top: none;
        padding: 10px 20px;
        max-height: 61px;

        &:last-child {
            border-right: none;
        }
    }

    @include media_desktop {
        border-bottom: 1px solid var(--color-dove-gray-a30);
        border-top: none;
        position: relative;
        max-width: px-to-vh(450px);
        margin: 0 auto;
        padding: px-to-vh(10px) px-to-vh(20px);
        max-height: px-to-vh(71px);
    }

    span {
        display: flex;
        align-items: center;
        color: var(--color-tuna);
        font-size: px-to-vw(13px);
        letter-spacing: 2px;
        width: 240px;
        height: 18px;
        margin-left: 20px;

        @include media_tablet {
            margin-left: 20px;
            font-size: 13px;
            width: 200px;
            height: 18px;
        }

        @include media_desktop {
            font-size: px-to-vh(13px);
            width: px-to-vh(298px);
            height: px-to-vh(18px);
        }
    }

    img {
        display: flex;
        margin: auto;
        width: 20px;
        height: 20px;
        min-height: 20px;

        @include media_tablet {
            margin-top: 10px;
        }

        @include media_desktop {
            margin-top: 15px;
        }
    }
}

.BackButton {
    left: 16px;
}

.TableCellImage {
    min-width: 20px;
}

.TitleText {
    color: var(--color-tuna);
    margin: 60px auto 40px 28px;
    text-align: left;
    font-size: px-to-vw(28px);

    @include media_tablet {
        font-size: 35px;
        margin: 70px auto 40px 28px;
        text-align: left;
    }
}
