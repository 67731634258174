@import '../../../../assets/scss/medias';

.ButtonWhite {
    background-color: var(--color-white-a60);
    border: solid 1px var(--color-tuna);
    color: var(--color-nepal);
    padding: 10px 24px 12px;
    white-space: nowrap;
    width: min-content;
    max-width: 100%;
    margin: 0 auto;

    @include media_tablet {
        padding: 8px 25px 10px;
        height: unset;
        letter-spacing: 3px;
    }

    @include media_desktop {
        padding: 11px 30px 15px;
    }
}
