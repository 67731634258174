@import '../../../assets/scss/medias';

.Input {
    width: 100%;
    appearance: none;
    background: none;
    border-radius: 3px;
    border: solid 1px var(--color-shark);
    color: var(--color-shark);
    display: block;
    font-size: 1.2rem;
    height: 3rem;
    outline: none;
    padding: 0.6rem;
    text-align: center;

    @include media_desktop {
        height: auto;
    }
}
