@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

$button-margin: 30px auto 60px;

.Button {
    margin: $button-margin;
    max-width: 280px;

    @include media_desktop {
        max-width: unset;
        margin: 30px 0 60px;
    }
}

.ButtonCustom {
    margin: $button-margin;
    border-radius: 3px;
    font-size: px-to-vw(12px);
    color: var(--color-tuna);
    padding: px-to-vw(14px) px-to-vw(24px);
    max-width: 280px;

    @include media_tablet {
        font-size: px-to-vh-tablet(12px);
        color: var(--color-tuna);
        padding: px-to-vh-tablet(14px) px-to-vh-tablet(30px);
        max-width: unset;
    }

    @include media_desktop {
        margin: px-to-vh(40px) 0 px-to-vh(60px);
        font-size: px-to-vh(12px);
        padding: px-to-vh(14px) px-to-vh(30px);
    }

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
