$tablet-width: 420px;
$desktop-width: 1025px;

@mixin media_tablet {
    @media only screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin media_desktop {
    @media only screen and (min-width: $desktop-width) {
        @content;
    }
}

@mixin media_custom($min-width, $max-width) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}
