@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.TableCellContainer {
    align-items: center;
    border-bottom: solid 1px var(--color-mercury);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 70px;
    margin: 0 auto;
    padding: 10px 20px;
    width: 95%;
    white-space: nowrap;

    @include media_tablet {
        border: 1px solid var(--color-dove-gray-a30);
        border-bottom: none;
        border-right: none;
        border-top: none;
        flex-direction: column;
        flex: 0 1 auto;
        margin: 0;
        padding: 8px 0 0;
        height: 99px;
        width: 142px;
        white-space: unset;

        &:last-child {
            border-right: 1px solid var(--color-dove-gray-a30);
        }
    }

    @include media_desktop {
        height: px-to-vh(132px);
        width: px-to-vh(233px);
    }

    img {
        max-height: 60px;
        min-width: px-to-vw(21px);
        max-width: none;

        @include media_tablet {
            height: px-to-vh-tablet(32px);
            min-height: px-to-vh-tablet(32px);
            min-width: unset;
        }

        @include media_desktop {
            height: 4.52962vh;
            min-height: 4.52962vh;
            min-width: unset;
        }
    }

    span {
        color: var(--color-dove-gray);
        letter-spacing: 3px;
        line-height: 1.42;
        margin-left: 35px;
        text-transform: uppercase;
        font-family: var(--font-medium);
        font-size: px-to-vw(12px);

        @include media_tablet {
            margin: 0 auto;
            font-size: 12px;
            width: 100px;
            text-align: center;
            overflow: hidden;
            display: -webkit-box;
            word-break: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        @include media_desktop {
            font-size: 1.48148vh;
            width: px-to-vh(162px);
        }
    }
}

.ImageContainer {
    align-items: center;
    display: flex;
    max-height: 25px;
    justify-content: center;
    max-width: 25px;

    @include media_tablet {
        height: 39px;
        width: 39px;
        margin-bottom: 16px;
    }

    @include media_desktop {
        height: 50px;
        width: 50px;
        margin-bottom: 24px;
    }
}
