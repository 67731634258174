@import '../../../../assets/scss/medias';

.CloseButton {
    top: 40px;
    right: 40px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: none;

    @include media_tablet {
        display: block;
        top: 25px;
        right: 29px;
    }

    @include media_desktop {
        top: 36px;
        right: 36px;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        height: 35px;
        width: 1px;
        background-color: var(--color-tuna);
        transform-origin: center;
        transform: rotate(45deg);
        top: 0;
        left: 50%;

        @include media_tablet {
            height: 40px;
        }
    }

    &:after {
        transform: rotate(-45deg);
    }
}
