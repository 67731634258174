@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.VibrationCircles {
    margin: 0 auto 1.85rem;
    width: 250px;
    height: 250px;
    position: relative;

    @include media_tablet {
        margin-top: 32px;
        margin-bottom: 2rem;
        width: 324px;
        height: 324px;
    }

    @include media_desktop {
        margin-bottom: 2rem;
        width: px-to-vh(432px);
        height: px-to-vh(432px);
    }
}

.First {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid var(--color-white-smoke);
}

.Second {
    border-radius: 50%;
    width: 82%;
    height: 82%;
    background: transparent;
    border: 2px solid var(--color-gainsboro);
    position: absolute;
    top: 9%;
    left: 9%;
}

.Third {
    border-radius: 50%;
    width: 80%;
    height: 80%;
    background: transparent;
    border: 2px solid var(--color-nobel);
    position: absolute;
    top: 10%;
    left: 10%;
}

.Fourth {
    border-radius: 50%;
    width: 70%;
    height: 70%;
    background: transparent;
    border: 2px solid var(--color-dim-gray);
    position: absolute;
    top: 15%;
    left: 15%;
}

.ChildContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    bottom: 10px;
}
