@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
}

.HeaderImgContainer {
    text-align: center;
}

.HeaderImg {
    height: 100vw;
    width: 100vw;
}

.Container {
    padding: 0 20px;
    text-align: center;
    min-height: 20vh;
}

.Container span {
    display: block;
    margin-top: 25px;
    letter-spacing: 0.1rem;
    font-family: var(--font-medium);
}

.Header {
    color: var(--color-charade);
    margin: 10px 0 20px;
}

.LastSlideButton {
    position: relative;
    margin-top: 45px;
    margin-bottom: 30px;
}

.SliderButton {
    background-color: var(--color-alabaster-lighten);
    top: 59%;
}

@include media_desktop {
    .ContentContainer {
        margin-top: px-to-vh(87px);
        outline: none;
    }

    .CleaninGuideBackButton {
        left: 0;
    }
}

.Dots {
    bottom: -25px;

    li {
        margin: 0 5px 0;

        button {
            &:before {
                font-size: 5px;
            }
        }

        @include media_desktop {
            margin: 0 10px 0;

            button {
                &:before {
                    font-size: 6px;
                }
            }
        }
    }

    @include media_desktop {
        bottom: -28px;
    }
}
