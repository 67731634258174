@import 'medias';
@import 'helpers';

@mixin txt--regular {
    font-size: px-to-vw(14px);
    color: var(--color-tuna);
    line-height: 1.86;

    @include media_tablet {
        font-size: 16px;
        line-height: 28px;
    }

    @include media_desktop {
        font-size: px-to-vh(20px);
        line-height: px-to-vh(36px);
    }

    &-fixed {
        font-size: 14px;

        @include media_desktop {
            font-size: px-to-vh(20px);
        }
    }
}
