@import '../../../../../assets/scss/medias';

.SettingsTable {
    @include media_tablet {
        display: flex;
        margin-top: 0;
        justify-content: center;
    }

    @include media_custom(420px, 1023px) {
        flex-wrap: wrap;

        & > div {
            border-right: 1px solid var(--color-dove-gray-a30);
        }
    }
}

.SettingsTableChargerHolderIcon {
    display: flex;

    & img:nth-child(2) {
        margin-left: 3px;
    }
}
