#root {
    --color-alabaster-lighten-a50: rgba(247, 247, 247, 0.5);
    --color-alabaster-lighten: #f7f7f7;
    --color-alabaster: #f9f9f9;
    --color-alto-lighten: #d2d2d2;
    --color-alto-second: #d3d3d3;
    --color-alto: #d9d9d9;
    --color-anzac: #e5b052;
    --color-athens-gray: #f8f8f9;
    --color-atlantis-lighten: #a0ca32;
    --color-atlantis: #a0ca32;
    --color-black-a10: rgba(0, 0, 0, 0.1);
    --color-black-a30: rgba(0, 0, 0, 0.3);
    --color-black: black;
    --color-bombay: #afb0b4;
    --color-charade-a20: rgba(39, 42, 51, 0.2);
    --color-charade-a60: rgba(39, 42, 51, 0.6);
    --color-charade-a90: rgba(39, 42, 51, 0.9);
    --color-charade: #272a33;
    --color-cinnabar: #dc3838;
    --color-corn: #e5c100;
    --color-dim-gray: #616161;
    --color-dove-gray-a30: rgba(102, 102, 102, 0.3);
    --color-dove-gray: #666666;
    --color-dusty-gray: #979797;
    --color-ebb: #e6e0dc;
    --color-gainsboro: #e0e0e0;
    --color-hint-of-red-a70: rgba(243, 237, 232, 0.7);
    --color-hint-of-red: #f3ede8;
    --color-mandy: #e55252;
    --color-mariner: #306fd6;
    --color-mercury: #e8e8e8;
    --color-nepal: #98adba;
    --color-nobel: #9e9e9e;
    --color-paarl: #9d6b25;
    --color-pearl-bush-a50: rgba(239, 233, 227, 0.7);
    --color-pearl-bush-lighten: #f0e9e5;
    --color-pearl-bush: #efe9e3;
    --color-revolver-a95: rgba(56, 56, 57, 0.95);
    --color-river-bed-a20: rgba(77, 93, 98, 0.2);
    --color-rodeo-dust: #cfb2a0;
    --color-shark: #2d3038;
    --color-storm-gray: #73757c;
    --color-swiss-cofee: #ded8d6;
    --color-tuna: #383839;
    --color-twine: #c29a69;
    --color-white-a50: rgba(255, 255, 255, 0.5);
    --color-white-a60: rgba(255, 255, 255, 0.6);
    --color-white-smoke: #f5f5f5;
    --color-white: white;
    --color-wild-sand: #f4f4f4;

    --font-bold: NotoSans-Bold;
    --font-light: NotoSans-Light;
    --font-medium: NotoSans-Medium;
    --font-regular: NotoSans-Regular;
}
