.IconFrontWrap {
    overflow: hidden;
    float: left;
    width: 100%;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.IconFront {
    float: left;
    width: 100%;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ArrowContainer {
    height: 16px;
    position: relative;
    float: left;
    margin-left: 12px;
}

.Arrow {
    width: 33px;
    height: 16px;
    transition: 2s ease;
}

.IconFront svg {
    fill: var(--color-rodeo-dust);
}

.IconBackWrap {
    transform: translate3d(-103%, 0, 0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.IconBack {
    transform: translate3d(103%, 0, 0);
    float: left;
    width: 100%;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.IconBack svg {
    fill: var(--color-white);
}

:global(.button-arrow__animation):hover .IconFrontWrap {
    transform: translate3d(103%, 0, 0);
}

:global(.button-arrow__animation):hover .IconFront {
    transform: translate3d(-103%, 0, 0);
}

:global(.button-arrow__animation):hover .IconBackWrap {
    transform: translateZ(0);
}

:global(.button-arrow__animation):hover .IconBack {
    transform: translateZ(0);
}
