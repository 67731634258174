@import '../../../../../assets/scss/medias';

.UpdateFWPopup {
    text-align: center;
}

.UpdateFWPopup h2 {
    width: 90%;
    margin: 0 auto;
    color: var(--color-tuna);
}

.UpdateFWPopupDescription {
    text-align: center;
    margin: 16px 0 30px;
    white-space: pre-line;

    @include media_desktop {
        width: 95%;
        margin: 2rem auto;
    }
}
