@import '../../../assets/scss/medias';

.Header {
    width: 100%;
    height: 36px;
    position: absolute;
    top: 0;
}

@include media_desktop {
    .Header {
        width: auto;
        margin-top: 42px;
    }
}
