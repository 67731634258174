@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.NotificationContainer {
    width: 95%;
    height: auto;
    border-radius: 3px;
    background-color: #f5f4f2;
    position: relative;
    margin-top: 10px;
    padding: 15px 24px 15px 15px;
    display: flex;
    align-items: center;
    z-index: 10;
    animation-name: notificationAnimation;
    animation-duration: 0.3s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    cursor: pointer;

    @include media_tablet {
        max-width: 500px;
        padding: 22px 25px;
        margin-top: 0;
    }

    @include media_desktop {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 700px;
        width: 100%;
        padding: 20px;
        box-shadow: 0 2px 8px 0 var(--color-black-a30);
        border-radius: 0;
    }
}

.SuccessfulMessageIcon,
.FailMessageIcon,
.InfoMessageIcon {
    width: 20px;
    height: 20px;
    margin-right: 17px;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: 3px;

    @include media_tablet {
        width: 25px;
        height: 25px;
    }

    @include media_desktop {
        margin: 0 15px 0 0;
        width: px-to-vh(30px);
        height: px-to-vh(30px);
    }
}

.Text {
    margin: 0;
    text-align: left;

    @include media_desktop {
        line-height: normal;
    }
}

@keyframes notificationAnimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        height: 0;
    }
}
