@import '../../../../../../../assets/scss/medias';
@import '../../../../../../../assets/scss/helpers';

.ShowLedProperty {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-family: var(--font-bold);

    @include media_tablet {
        padding: 0;
        font-family: var(--font-medium);
    }
}

.CenterElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.ImageLedDayIcon {
    width: 21px;
    height: 21px;

    @include media_tablet {
        height: 30px;
        width: 30px;
    }

    @include media_desktop {
        height: px-to-vh(40px);
        width: px-to-vh(40px);
    }
}

.ImageVapeSizeNormalIcon {
    width: 27px;
    height: 16px;

    @include media_tablet {
        width: 40px;
        height: 24px;
    }

    @include media_desktop {
        width: 54px;
        height: 32px;
    }
}

.ImageVapeSizeReducedIcon {
    width: 19px;
    height: 14px;

    @include media_tablet {
        width: 29px;
        height: 21px;
    }

    @include media_desktop {
        width: 38px;
        height: 28px;
    }
}

.ImageCleaningHeaderIcon {
    width: 16px;
    height: 22px;

    @include media_tablet {
        width: 24px;
        height: 33px;
        max-width: 24px;
        max-height: 33px;
    }

    @include media_desktop {
        width: px-to-vh(32px);
        height: px-to-vh(44px);
        max-width: 32px;
        max-height: 44px;
    }
}

.ImageVibrateHeaderIcon {
    width: 34px;
    height: 24px;

    @include media_tablet {
        width: 50px;
        height: 36px;
        max-width: 50px;
        max-height: 36px;
    }

    @include media_desktop {
        width: px-to-vh(50px);
        height: px-to-vh(48px);
        max-height: 48px;
    }
}

.ImageLedHeaderIcon {
    width: 19px;
    height: 20px;

    @include media_tablet {
        width: 30px;
        height: 30px;
        max-width: 30px;
        max-height: 30px;
    }

    @include media_desktop {
        width: px-to-vh(38px);
        height: px-to-vh(40px);
        max-width: 38px;
        max-height: 40px;
    }
}

.ImageResponsiveDrawHeaderIcon {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.VibrateHeader {
    text-align: left;
    margin: 0;
}

.SeparateLine {
    width: 100%;
    height: 1px;
    background-color: var(--color-mercury);
    margin-top: 20px;
    margin-bottom: 20px;
}

.LedModeButton {
    background-color: var(--color-white);
    border: solid 1px var(--color-shark);
    color: var(--color-charade);
    height: 3.2rem;
    line-height: 3.2rem;
    max-width: px-to-vw(150px);

    @include media_tablet {
        width: 47.8%;
        height: 72px;
        margin-bottom: 0;
    }

    @include media_desktop {
        max-width: px-to-vh(480px);
        width: 100%;
        height: auto;
    }

    &.Active {
        background-color: var(--color-athens-gray);
        border-width: 2px;
        cursor: default;
    }
}

.DevicePicto {
    width: 100%;
    position: relative;
    margin: 30px auto 30px;

    @include media_desktop {
        margin: 30px auto 46px;
    }
}

.ImageCleaningContainer {
    height: 220px;
    z-index: 2;

    @include media_tablet {
        height: 300px;
    }

    @include media_desktop {
        height: px-to-vh(412px);
    }
}

.LoaderStaticContainer {
    width: 240px;
    height: 240px;
    opacity: 0.2;
    border: solid 1px var(--color-dusty-gray);
    border-radius: 50%;
}

.LoaderStatic {
    width: 200px;
    height: 200px;
    border: solid 1px var(--color-shark);
    border-radius: 50%;
}

.Description {
    margin-bottom: 35px;
}

.ImageContainer {
    min-width: 60px;
    height: 270px;

    @include media_tablet {
        height: 309px;
        margin-top: 20px;
    }

    @include media_desktop {
        height: px-to-vh(412px);
    }
}

.PopupBody {
    @include media_desktop {
        margin-top: 2rem;
    }
}

.GoogleMap {
    margin: 15px 0;
    height: 30vh;
    width: 100%;
}

.HeaderTitle {
    text-align: left;
    font-size: 24px;
    margin: 1.5rem 0 0;

    @include media_tablet {
        font-size: 24px;
        margin: 30px 0 15px;
    }

    @include media_desktop {
        font-size: 2.59259vh;
        margin: px-to-vh(32px) 0 px-to-vh(20px);
    }
}

.ButtonsContainer {
    margin-bottom: 2rem;
    position: relative;

    @include media_tablet {
        margin-bottom: 32px;
    }

    @include media_desktop {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: px-to-vh(-10px) 0 px-to-vh(35px) 0;
    }
}

.ButtonsContainer::after {
    content: '';
    position: absolute;
    width: auto;
    bottom: -20px;
    left: -15px;
    right: -15px;
    height: 1px;
    background-color: var(--color-mercury);

    @include media_desktop {
        display: none;
    }
}

.ButtonContainer {
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;

    @include media_desktop {
        margin-top: 2rem;

        button {
            max-width: 480px;
            width: px-to-vh(480px);
            height: px-to-vh(96px);
        }
    }

    &.LedMode {
        margin-top: 0.5rem;

        @include media_tablet {
            margin-top: 24px;
        }

        @include media_desktop {
            margin-top: 1.6rem;
        }
    }

    &.DrawMode {
        flex-direction: column;

        @include media_tablet {
            margin: 32px auto 0px;
            width: 370px;
            height: auto;
        }

        @include media_desktop {
            width: auto;
            flex-wrap: wrap;
            flex-direction: row;
        }

        button {
            margin: 0 auto 10px;

            @include media_desktop {
                margin: 0 auto 20px;
            }
        }
    }
}

.ResponsiveDrawButtonTitle {
    @include media_desktop {
        text-transform: none;
        font-size: px-to-vh(20px);
    }
}

.VapeCloudButtons {
    display: flex;
    justify-content: space-between;

    @include media_tablet {
        margin-top: 22px;
    }

    @include media_desktop {
        margin-top: 1.6rem;
    }

    button {
        @include media_desktop {
            height: px-to-vh(96px);
            font-size: 20px;
            line-height: 36px;
            padding: 0 30px;
        }
    }
}

.ContentContainer {
    @include media_tablet {
        margin: 0 auto;
        width: 370px;
        height: auto;
    }

    @include media_desktop {
        margin: auto;
        width: auto;
    }
}
