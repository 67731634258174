.Container {
    position: relative;
}

.Image {
    position: absolute;
    max-width: none;
    width: auto;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
