@import '../../../../../../../assets/scss/medias';

.SettingsPopup {
    text-align: center;
}

.SettingsPopupHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin: 10px 0 20px;
    }
}

.ButtonDecline {
    @include media_desktop {
        margin-bottom: 1rem;
    }
}

.ButtonAccept {
    @include media_desktop {
        margin: 0;
    }
}

.ButtonDisabled {
    opacity: 0.4;
}
