@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include media_tablet {
        background-color: var(--color-alabaster-lighten);
    }
}

.SelectBlock {
    @include media_tablet {
        position: relative;
    }
}

.ActiveSelectButton {
    border: solid 2px var(--color-charade);
    border-bottom: solid 0.5px var(--color-dove-gray-a30);
}

.DownChevron {
    transform: rotateX(180deg);
}

.TitleText {
    max-height: 72px;
    max-width: 320px;
    font-family: var(--font-light);
    margin: 0 auto 40px;
    text-align: center;
    color: var(--color-tuna);
    font-size: px-to-vw(26px);

    @include media_tablet {
        font-family: var(--font-medium);
        letter-spacing: -2px;
        max-height: 54px;
        max-width: 900px;
        font-size: 35px;
    }

    @include media_desktop {
        max-height: 64px;
        max-width: 1170px;
        font-size: px-to-vh(50px);
    }
}

.SelectButton {
    width: 75%;
    color: var(--color-charade);
    justify-content: left;
    font-size: px-to-vw(14px);
    font-family: var(--font-regular);
    text-transform: capitalize;
    letter-spacing: 0;
    margin: 0.3rem auto;
    padding: 10px 14px;

    @include media_tablet {
        font-size: 14px;
        text-align: left;
        height: 40px;
        max-width: 380px;
        margin: 6px auto 12px;
    }

    @include media_desktop {
        margin: px-to-vh(6px) auto px-to-vh(12px);
        font-size: px-to-vh(16px);
        height: px-to-vh(44px);
        max-width: px-to-vh(450px);
    }
}

.InactiveSelectButton {
    opacity: 0.4;
}

.ConfirmButton {
    font-size: px-to-vw(10px);
    width: 164px;
    height: 36px;
    margin: 30px auto 58px;

    @include media_tablet {
        margin: 20px auto 0;
        font-size: 12px;
        width: 190px;
        height: 40px;
    }

    @include media_desktop {
        margin: px-to-vh(24px) auto 0;
        padding: px-to-vh(11px) px-to-vh(30px) px-to-vh(15px);
        font-size: px-to-vh(16px);
        width: min-content;
        max-width: 215px;
        height: px-to-vh(48px);
        max-height: 48px;
    }
}

.Label {
    width: 75%;
    font-size: px-to-vw(14px);
    margin: 20px auto 0;
    letter-spacing: 0;
    color: var(--color-tuna);

    @include media_tablet {
        color: var(--color-charade);
        margin: 0 auto 6px;
        font-size: 16px;
        max-width: 380px;
    }

    @include media_desktop {
        margin: 0 auto px-to-vh(6px);
        height: px-to-vh(28px);
        font-size: px-to-vh(16px);
        max-width: px-to-vh(450px);
    }
}

.Container {
    position: inherit;

    @include media_tablet {
        padding: 36px 0;
        border-top: solid 1px var(--color-gainsboro);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
        height: auto;
        width: px-to-vw(420px);
        min-width: 200px;
        max-width: 460px;
        max-height: 360px;
        background-color: var(--color-white);
    }

    @include media_desktop {
        padding: px-to-vh(36px) 0;
        width: auto;
        min-width: 0;
        max-width: px-to-vh(570px);
        max-height: px-to-vh(402px);
    }
}

.Icon {
    display: block;
    margin: px-to-vh(58px) auto 1.4rem;
    max-width: 44px;
    max-height: 61px;

    @include media_desktop {
        width: 55px;
        height: 76px;
    }
}
