.Loader {
    width: 300px;
    max-width: 300px;
    height: 300px;
    max-height: 300px;
}

.Loader circle {
    width: 300px;
    height: 300px;
    fill: none;
}

.LoaderCircleStatic {
    stroke: var(--color-swiss-cofee);
    stroke-width: 1px;
}

.LoaderCircleProgress {
    stroke-width: 2px;
    stroke: var(--color-atlantis);
    stroke-dasharray: 1100;
    transform: rotate(270deg);
    transform-origin: center;
}
