@import '../../../../../../assets/scss/helpers';
@import '../../../../../../assets/scss/medias';

.Divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: px-to-vw(20px) 0;

    @include media_tablet {
        margin: px-to-vh-tablet(35px) auto;
        max-width: px-to-vh-tablet(531px);
    }

    @include media_desktop {
        margin: px-to-vh(35px) 0;
        max-width: px-to-vh(531px);
    }
}

.Line {
    height: 1px;
    background: #caccce;
    width: px-to-vw(80px);

    &:first-child {
        margin-right: px-to-vw(21px);

        @include media_tablet {
            margin-right: px-to-vh-tablet(27px);
        }

        @include media_desktop {
            margin-right: px-to-vh(27px);
        }
    }

    &:last-child {
        margin-left: px-to-vw(21px);

        @include media_tablet {
            margin-left: px-to-vh-tablet(27px);
        }

        @include media_desktop {
            margin-left: px-to-vh(27px);
        }
    }

    @include media_tablet {
        width: px-to-vh-tablet(230px);
    }

    @include media_desktop {
        width: px-to-vh(230px);
    }
}

.Text {
    color: var(--color-tuna);
    font-size: px-to-vw(16px);
    line-height: px-to-vw(32px);

    @include media_tablet {
        font-size: px-to-vh-tablet(18px);
        line-height: px-to-vh-tablet(32px);
    }

    @include media_desktop {
        font-size: px-to-vh(16px);
        line-height: px-to-vh(32px);
    }
}
