@import '../../../../assets/scss/medias';

.FixedContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    z-index: 11;
    transform: translate(-50%, -50%);

    @include media_tablet {
        position: absolute;
    }
}
