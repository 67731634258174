@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Page {
    width: 100%;
    padding-bottom: 60px;

    @include media_desktop {
        padding-bottom: 0;
    }
}

@include media_desktop {
    .RegistrationButtonMargin {
        margin: 0;
    }
}

.HeaderContainer {
    @include media_desktop {
        position: absolute;
        top: 0;
    }
}

.CommonContainer {
    width: 100%;

    h2 {
        margin: 0;
    }

    @include media_tablet {
        display: flex;
        padding-top: 63px;
        width: px-to-vw-tablet(624px);
        justify-content: center;
    }

    @include media_desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content;
        padding-top: 5.35rem;
        width: auto;
    }
}

.Container {
    padding: 60px 27px 32px 28px;
}

.PopupHeader {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.PopupHeaderTitle {
    margin-top: 10px;
}

.PopupBody {
    text-align: center;
}

.PopupButton {
    margin: 1rem 0;
}

.UpdateFWPopup {
    text-align: center;

    h2 {
        color: var(--color-tuna);
        margin: 0 auto;
        width: 90%;
    }
}

.UpdateFWPopupDescription {
    margin: 16px 0 30px;
    text-align: center;
}
